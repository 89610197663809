import CONSTANTS from 'src/lib/constants';

var DefaultConfig = {
	app: {
		menu: [
			{name: 'dashboard', text: 'Dashboard', icon: 'dashboard', path: '/dashboard'},
			{name: 'gallery', text: 'Gallery', icon: 'photo_library', path: '/gallery'},
			{name: 'feeds', text: 'Feed', icon: 'storage', items: [
				{ name: 'home-feed', text: 'Home', path: '/feed', icon: 'home' },
				{ name: 'guest-feed', text: 'Guest', path: `/feed/${CONSTANTS.GUEST_FEED_ID}`, icon: 'nest_doorbell_visitor' },
				{ name: 'family-feed', text: 'Family', path: `/feed/${CONSTANTS.FAMILY_FEED_ID}`, icon: 'family_restroom' },
				{ name: 'feed-strip', text: 'Feed Strip', path: `/feed/${CONSTANTS.FEED_STRIP_FEED_ID}`, icon: 'flex_no_wrap' },
				{ name: 'feeds', text: 'Feeds', path: '/feeds', icon: 'amp_stories' },
			]},
			{name: 'experiences', text: 'Experiences', icon: 'explore', items: [
				{ name: 'new', text: 'New', path: '/experience/new', icon: 'add' },
				{ name: 'search', text: 'Search', path: '/experiences/search', icon: 'search' },
				{ name: 'gallery', text: 'Gallery', path: '/experiences', icon: 'collections' },
				{ name: 'lists', text: 'Feeds', path: '/experienceFeed', icon: 'grid_view' },
				{ name: 'instructors', text: 'Instructors', path: '/instructors', icon: 'group' },
			]},
			{ name: 'materials', text: 'Materials', icon: 'category', items: [
				{ name: 'papers', text: 'Papers', path: '/papers', icon: 'wallpaper' },
			]},
			{ name: 'events', text: 'Events', path: '/events', icon: 'star' },
			{ name: 'spaces', text: 'Spaces', icon: 'chat_bubble', path: '/space-posts' },
			{name: 'utils', text: 'Utils', icon: 'build', items: [
				{name: 'image-editor', text: 'Editor', icon: 'palette', path: '/image/editor'},
				{name: 'media-library', text: 'Media Lib', icon: 'cloud_upload', path: '/media-lib'},
				{name: 'live-config', text: 'Live Config', icon: 'live_tv', path: '/live'},
				{name: 'experiences-filter-config', text: 'Exp Filter Config', icon: 'live_tv', path: '/experiences/filter/config'}
			]}
		]
	},
	services: {
		logger: {
			length: 1000
		},
		google: {
			authKey: ''
		},
		experience: {
			expDeepLinkTemplate: 'https://site.dev.cupixel.com/applink/experience/'
		},
		lod: {
			scaleOutline: 5, // It is important to user the number that was used when training the model (5)
			models: {
				PCA: { // MAE: 90.2 RRSE: 58.7826
					pixels: 0.3299,
					colors: 28.0408,
					area: -0.0007,
					K: 82.2408
				},
				PC: { // MAE: 88.9 RRSE: 58.3
					pixels: 0.3417,
					colors: 30.5535,
					K: 52.3197
				},
				LCA: { // MAE: 89.8  RRSE: 58.6
					length: 0.0521,
					colors: 27.8924,
					area: -0.0008,
					K: 86.1842
				},
				LC: { // MAE: 89.3 RRSE: 58.6
					length: 0.054,
					colors: 30.4083,
					K: 56.0483
				}
			}
		},
		audit: {
			strict: true, // Whether audit has to pass
			severities: {}, // Dictionary represents for each error its severity (overrides defaults that in from audit-errors.js)
			rules: {
				colors: {
					maxColors: 30, // Maximum number of unique colors allowed
					maxPaint: 30 // Then percentage of maximum paint allowed per color
				},
				mc: {
					models: 'LC, LCA', // Models to use to calc lod
					lod: 900, // Number of seconds per MC
					minArea: 0.0005,
					maxColors: 10
				}
			}
		},
		palette: {
			colorSpace: 'acrylic'
		}
	},
	data: {
		showAllUsers: false,
		cloudinaryBaseUrl: 'https://api.cloudinary.com/v1_1/cupixel',
		lookerDashboards: {
			home: ''
		},
		mongoChartsDashboard: {
			home: {
				url: '',
				id: ''
			}
		}
	}
};

export default DefaultConfig;