var CONSTANTS = {
	FEED_STRIP_FEED_ID: '000000000000000000000000',
	HOME_FEED_ID: '111111111111111111111111',
	FAMILY_FEED_ID: '222222222222222222222222',
	GUEST_FEED_ID: '333333333333333333333333',

	FeedItemType: {
		Feed: 0,
		ExperienceFeed: 1,
		Experience: 2,
		Banner: 3,
		Video: 4,
		TimelapseScreen: 5,
		Screen: 6,
		Title: 7
	},

	FeedItemScreenName: {
		Discount: 'Discount',
		Timelapse: 'Timelapse'
	},

	FeedItemUIElementType: {
		None: 'None',
		FullCardImage: 'FullCardImage',
		FullCardVideo: 'FullCardVideo',
		Strip: 'Strip',
		Slideshow: 'Slideshow',
		FeaturedCard: 'FeaturedCard'
	},

	ExperiencePreviewType: {
		Image: 'Image',
		Video: 'Video'
	},

	ExperienceKind: {
		Informative: 0,
		Creative: 1
	},

	ExperienceStatus: {
		Draft: 0,
		Public: 1,
		RC: 2
	},

	ExperienceAccessLevel: {
		Guest: 0,
		User: 1,
		Member: 2
	},

	SpacePostAccessLevel: {
		Locked: 0,
		User: 1,
		Member: 2,
		Instructor: 3
	},

	PersonalPhotoAccessLevel: {
		// Locked: 0,
		Guest: 1,
		// User: 2,
		Member: 3
	},

	ExperienceLOD: {
		FamilyOnly: 0,
		BothFamilyAndCreators: 1,
		CreatorsOnly: 2,
		None: 999
	},

	CreationTabs: {
		Studio: 'Studio',
		Artwork: 'Artwork',
		Trace: 'Trace',
		Space: 'Space'
	},

	ArtworkType: {
		Gallery: 'Gallery',
		Photo: 'Photo',
		Text: 'Text'
	},

	Audience: {
		Single: 'Single',
		Couples: 'Couples',
		Family: 'Family',
		Kids: 'Kids'
	},

	Product: {
		Canvas: 'Canvas',
		Sketch: 'Sketch',
		Paper: 'Paper',
		StickyNote: 'StickyNote',
		GreetingCard: 'GreetingCard',
		Postcard: 'Postcard'
	},

	MediaAssetStatus: {
		Draft: 'Draft',
		Public: 'Public',
		Deleted: 'Deleted'
	},

	AssetTypes: {
		Image: 'Image',
		Video: 'Video',
		PDF: 'PDF',
	},

	AssetViewModes: {
		Gallery: 'Gallery',
		Edit: 'Edit'
	},

	SpacePostMediaType: {
		Image: 'Image',
		Video: 'Video'
	},

	SharedPartners: {
		Joann: 'JAS'
	}
};

export default CONSTANTS;
